import logoWhite from "#/src/assets/svg/logo-white.svg";
import logoDark from "#/src/assets/svg/logo-dark.svg";
import React from "react";
import {usePageContext} from "vike-react/usePageContext";
import {AppPageContext} from "#/src/definitions/AppDefinitions";

export function BrandLogo() {
  const {user} = usePageContext() as AppPageContext;
  if (user.lightMode) {
    return <img width={184} height={48} src={logoDark} alt={"Ja, Rock"} className={"brand logo-dark"}/>
  }
  return <img width={184} height={48} src={logoWhite} alt={"Ja, Rock"} className={"brand logo-white"}/>
}